
// This file supports running on the server and in the browser, b/c it's just a thin
// wrapper around `rocketship-copydeck`, which also has that support.

const copydeck = require('rocketship-copydeck');

class RSCDBackend {

    // Overriding Node-Backend's read functionality so we can pull from RS-copydeck
    // and use the caching there.
    async read (language, namespace, callback) {
        const data = await copydeck.get(namespace, language);
        callback(null, data);
    }
}

RSCDBackend.type = 'backend';

module.exports = RSCDBackend;
